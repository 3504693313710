import React from "react"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import styles from "./styles.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 600,
					tabName: "Яндекс Еда",
					desktopTitle: "Интеграция\nна любой вкус",
					tabletTitle: "Интеграция на любой вкус",
					mobileTitle: "Интеграция\nна любой вкус",
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Прием заказов, оформленных через приложение и&nbsp;сайт агрегатора,
							на&nbsp;кассовом терминале, доставка заказов курьерами сервиса или
							с&nbsp;помощью своих сотрудников, автоматическое обновление меню при
							изменениях в&nbsp;бэк-офисе.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/integrations-delivery-2.png"
							alt={"интеграция quick resto и яндекс еда"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 600,
					tabName: "Яндекс Доставка",
					desktopTitle: "Сотрудничай с сильнейшими",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Незаменимый партнёр для доставки заказов в&nbsp;пиковые часы. Среднее
							время на&nbsp;поиск курьера&nbsp;&mdash; 2&nbsp;минуты, время ожидания
							курьера&nbsp;&mdash; 10&nbsp;минут. Управление напрямую
							с&nbsp;кассового терминала.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/integrations-delivery-3.png"
								alt={"интеграция quick resto и яндекс доставка"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 600,
					tabName: "Яндекс Еда",
					desktopTitle: "Кез келген талғамға\nсай келетін интеграция",
					tabletTitle: "Кез келген талғамға сай келетін интеграция",
					mobileTitle: "Кез келген\nталғамға сай келетін интеграция",
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Агрегатордың қосымшасы мен сайты арқылы ресімделген тапсырыстарды кассалық терминалда қабылдау,
							тапсырыстарды сервис курьерлері арқылы немесе өз қызметкерлері көмегімен жеткізу, бэк-офисте
							өзгерістер болған кезде мәзірді автоматты түрде жаңарту.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/integrations-delivery-2.png"
							alt={"интеграция quick resto и яндекс еда"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 600,
					tabName: "Яндекс Доставка",
					desktopTitle: "Ең күштілермен ынтымақтасыңыз",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Қарбалас сәтте тапсырыстарды жеткізу үшін таптырмайтын серіктес. Курьерді іздеуге орташа уақыт
							— 2 минут, курьерді күту уақыты — 10 минут. Кассалық терминалдан тікелей басқару.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/integrations-delivery-3.png"
								alt={"интеграция quick resto и яндекс доставка"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 600,
					tabName: "Яндекс Еда",
					tabNameTablet: "Я. Еда",
					tabNameMobile: "Я. Еда",
					desktopTitle: "Интеграция\nна любой вкус",
					tabletTitle: "Интеграция на любой вкус",
					mobileTitle: "Интеграция\nна любой вкус",
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Прием заказов, оформленных через приложение и&nbsp;сайт агрегатора,
							на&nbsp;кассовом терминале, доставка заказов курьерами сервиса или
							с&nbsp;помощью своих сотрудников, автоматическое обновление меню при
							изменениях в&nbsp;бэк-офисе.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/integrations-delivery-2.png"
							alt={"интеграция quick resto и яндекс еда"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 600,
					tabName: "Яндекс Доставка",
					tabNameTablet: "Я. Доставка",
					tabNameMobile: "Я. Доставка",
					desktopTitle: "Партнёр по логистике",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Незаменимый партнёр для доставки заказов в&nbsp;пиковые часы. Среднее
							время на&nbsp;поиск курьера&nbsp;&mdash; 2&nbsp;минуты, время ожидания
							курьера&nbsp;&mdash; 10&nbsp;минут. Управление напрямую
							с&nbsp;кассового терминала.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/integrations-delivery-3.png"
								alt={"интеграция quick resto и яндекс доставка"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 600,
					tabName: "Яндекс Чек",
					tabNameTablet: "Я. Чек",
					tabNameMobile: "Я. Чек",
					desktopTitle: <>Оплата заказа по&nbsp;QR-коду</>,
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Оплата по QR-коду, телефоном через СБП и Yandex Pay &mdash;
							cтабильный и удобный способ, даже когда платёжный
							терминал потерял связь или подвис.
						</p>
					),
					tabletContent: (
						<p className={mediaContent.smallDescription}>
							Стабильный и удобный способ оплаты, даже когда
							платежный терминал потерял связь или «подвис».
							Оплата счета по QR-коду, телефоном через СБП и Yandex&nbsp;Pay.
						</p>
					),
					mobileContent: (
						<p className={mediaContent.smallDescription}>
							Стабильный и удобный способ оплаты, даже когда
							платежный терминал потерял связь или «подвис».
							Оплата счета по QR-коду, телефоном через СБП и Yandex&nbsp;Pay.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/integrations-delivery-4.png"
								alt={"интеграция quick resto и яндекс чек"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				}
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 600,
				tabName: "Деливери",
				desktopTitle: <>Добро пожаловать в&nbsp;клуб</>,
				desktopContent: (
					<p className={mediaContent.mediumDescription}>
						Интеграция расширяет зону доставки, открывает доступ к&nbsp;аудитории
						агрегатора. Гибкая работа со&nbsp;службой доставки&nbsp;&mdash; сам
						выбираешь чьи курьеры отвезут заказ. Информация обо всех акциях
						и&nbsp;спец предложениях транслируется пользователям агрегатора.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/integrations-delivery_delivery.png"
						alt={"интеграция quick resto и delivery club"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				detailLink: pagesLinks.deliveryClub,
				backdropContent: <div className={styles.backdrop} />,
			},
			{
				desktopContentWidth: 600,
				tabName: "Купер",
				desktopTitle: <>Доставка из магазинов и ресторанов</>,
				desktopContent: (
					<p className={mediaContent.mediumDescription}>
						Крупный федеральный сервис доставки, которому доверяют миллионы клиентов. Принимай заказы
						через онлайн-витрину — все они отобразятся на кассовом терминале.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/integrations-delivery_kuper.png"
						alt={"логотип купер"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.backdrop} />,
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	}
}
